<template>
  <div>
    <!--begin::Head-->
    <div class="kt-login__head">
      <span class="kt-login__signup-label">Already have an account?</span
      >&nbsp;&nbsp;
      <router-link class="kt-link kt-login__signup-link" :to="{ name: 'login' }"
        >Login!</router-link
      >
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Sign Up</h3>
        </div>

        <div id="app">{{ info }}</div>
        <div id="app">{{ key }}</div>

        <div
          role="alert"
          v-show="errors.length > 0"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-0"
            label
            label-for="example-input-0"
          >
            <b-form-input
              id="example-input-0"
              name="example-input-0"
              v-model="$v.form.firstname.$model"
              :state="validateState('firstname')"
              aria-describedby="input-0-live-feedback"
              placeholder="Name"
            ></b-form-input>

            <b-form-invalid-feedback id="input-0-live-feedback"
              >First name is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-1"
            label
            label-for="example-input-1"
          >
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              placeholder="Email address"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >Email is required and a valid email
              address.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-3"
            label
            label-for="example-input-3"
          >
            <b-form-input
              id="example-input-3"
              name="example-input-3"
              aria-describedby="input-3-live-feedback"
              placeholder="Phone"
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              type="text"
              style="display: none"
            ></b-form-input>

            <vue-tel-input
              id="example-input-3"
              name="example-input-3"
              @validate="yourValidationMethod"
              type="tel"
              class="form-select mt-4"
              enabledCountryCode="true"
              validCharactersOnly="true"
              v-model="$v.form.phone.$model"
              :placeholder="parsedPlaceholder"
              @onInput="onInput"
              style="padding-left: 0px !important; color: black !important"
            ></vue-tel-input>
            <b-form-invalid-feedback id="input-3-live-feedback"
              >Phone is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <div v-show="Isphonevalid != null && Isphonevalid == false">
            <p style="color: #fd397a; font-size: 90%" class="mb-0">
              A valid mobile no is required
            </p>
          </div>

          <b-form-group
            id="example-input-group-4"
            label
            label-for="example-input-4"
          >
            <b-form-select
              id="example-input-4"
              name="example-input-4"
              aria-describedby="input-4-live-feedback"
              v-model="$v.form.gender.$model"
              :options="gender"
              class="form-select"
              :state="validateState('gender')"
            ></b-form-select>

            <b-form-invalid-feedback id="input-4-live-feedback"
              >Please select a gender.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-2"
            label
            label-for="example-input-2"
          >
            <b-form-input
              type="password"
              id="example-input-2"
              name="example-input-2"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
              placeholder="Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback"
              >Password is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <div class="mt-4" :loadRecaptchaScript="true">
            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              sitekey="6LcJH-gUAAAAAIe5Bdw4jKdJs1qKxCcR3658tlCr"
            ></vue-recaptcha>
          </div>

          <b-form-group
            id="example-input-group-5"
            label
            label-for="example-input-5"
          >
            <b-form-input
              id="example-input-5"
              name="example-input-5"
              v-model="$v.form.captchaToken.$model"
              :state="validateState('captchaToken')"
              aria-describedby="input-5-live-feedback"
              placeholder="captchaToken"
              style="display: none"
            ></b-form-input>
            <b-form-invalid-feedback id="input-5-live-feedback"
              >Please verify as human.</b-form-invalid-feedback
            >
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <b-button
              v-on:click="$router.push('login')"
              class="btn btn-secondary btn-elevate kt-login__btn-primary"
              >{{ $t("AUTH.GENERAL.BACK_BUTTON") }}</b-button
            >

            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right"
              >{{ $t("AUTH.REGISTER.TITLE") }}</b-button
            >
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style scoped>
.custom-select.is-invalid {
  border: 0 !important;
}

.form-select {
  border: none;
  height: 50px;
  margin-top: 1.25rem;
  background-color: rgba(247, 247, 249, 0.7);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  color: #68737d;
}

.dropdown-menu {
  border: none;
  height: 50px;
  margin-top: 1.25rem;
  background-color: rgba(247, 247, 249, 0.7);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  box-shadow: none;
}

.custom-select:focus {
  box-shadow: none;
}

.invalid-feedback {
  font-size: 90%;
}

[id^="example-input-6"] {
  box-shadow: none !important;
}

.form-control {
  line-height: 2.5 !important;
}

.as-select input {
  margin-top: 0 !important;
}

.custominvalid-feeback {
  width: 100%;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #fd397a !important;
}
</style>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/store/auth.module";
import { LOGOUT } from "@/store/auth.module";
//import { SETERROR } from "@/store/auth.module";

import VueRecaptcha from "vue-recaptcha";
import { VueTelInput } from "vue-tel-input";
import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "register",

  components: { VueRecaptcha, VueTelInput },
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        phone: "",
        gender: null,
        captchaToken: "",
        dateofbirth: "",
      },
      number: [],
      country: [],
      finishMounted: false,
      info: null,
      Isphonevalid: null,
      key: "",
      gender: [
        { value: null, text: "Select a gender." },
        { value: "1", text: "Male" },
        { value: "2", text: "Female" },
      ],
    };
  },
  validations: {
    form: {
      firstname: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
      phone: {},
      gender: {
        required,
      },
      captchaToken: {
        required,
      },
    },
  },
  methods: {
    yourValidationMethod: function ({ number, isValid, country }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      //console.log( " yourValidationMethod " + number + " " + isValid + " " + country.name);

      if (this.$v.form.phone.$model != "") {
        this.Isphonevalid = isValid;
      }

      this.number = number;
      this.country = country;

      return isValid;
    },
    onInput({ number, isValid, country }) {
      this.number = number;
      this.country = country;
      //console.log(number + " " + isValid + " " + country.name);
      this.Isphonevalid = isValid;
    },

    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.$v.form.captchaToken.$model = recaptchaToken;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null,
        phone: null,
        gender: null,
        captchaToken: "",
        firstname: "",
        lastname: "",
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (!this.Isphonevalid) {
        //this.$store.dispatch(SETERROR ,"Please enter valid mobile no");
        return;
      }

      const firstname = this.$v.form.firstname.$model;
      const phone = this.$v.form.phone.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const gender = this.$v.form.gender.$model;
      const captchaToken = this.$v.form.captchaToken.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right"
      );

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      this.$store
        .dispatch(REGISTER, {
          email: email,
          password: password,
          mobileno: phone,
          FirstName: firstname,
          LastName: "",
          gender: gender,
          dateofbirth: "",
          CaptchaResponse: captchaToken,
        })
        .then(() =>
          this.$router.push({ name: "confirmation", params: { eid: email } })
        )
        .catch((error) => {
          if (error)
            submitButton.classList.remove(
              "kt-spinner",
              "kt-spinner--light",
              "kt-spinner--right"
            );
          this.$refs.recaptcha.reset();
          this.$v.form.captchaToken.$model = "";
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return "url(' + require('@/assets/media/bg/bg-4.jpg')";
    },
  },
  created() {
    this.key = this.$route.params.key;
  },
};
</script>
